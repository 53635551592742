/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-size: 42px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.01em;
}

.burgerMenu-appear {
  transform: translateX(500px);
  opacity: 0;
}
.burgerMenu-appear-active {
  transform: translateX(0);
  opacity: 1;
  transition: opacity 400ms, transform 400ms;
}

.burgerMenu-exit {
  transform: translateX(0);
  opacity: 1;
  transition: opacity 400ms, transform 400ms;
}
.burgerMenu-exit-active {
  transform: translateX(500px);
  opacity: 0;
  transition: opacity 400ms, transform 400ms;
}
